@import "../../styles/variables.scss";

.admin-header {
  height: 50px;
  background: $tuna;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1002;

  .content {
    display: flex;
    align-items: center;
  }

  .logo {
    margin-right: 12px;
    cursor: pointer;
  }

  .help-icon {
    color: $white;
    fill: currentColor;
  }
  .header-admin-title {
    color: $selec-react-hover-bg-color;
  }
  .logged-in-admin-user-icon {
    margin-right: 24px;
  }
  .logo-link{
    display: flex;
    align-items: center;
    text-decoration: none;
    outline: none;
    .header-title{
      font-family: $eyInterstateLightNew;
      font-size: $fontSize16;
      color: $white;
    }
  }
}
